import React, { Component } from "react";
import Request from "Library/Request";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileIconDisplay from "./FileIconDisplay";

export default class NewsDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
        };
        this._loadNews = this._loadNews.bind(this);
    }

    _renderFiles(news) {
        return (
            <div>
                <p className="mb-0 mt-4 text-secondary small">Angefügte Dateien:</p>
                <ul className="list-group">
                    {news.files.length > 0 &&
                        news.files.map((file) => {
                            return (
                                <li key={file._id} className="list-group-item pl-0 pb-0 border-0">
                                    <FileIconDisplay fileType={file.fileType} />
                                    <a className="ml-2" href={file.path} target="_blank" rel="noopener noreferrer">
                                        {file.title ? file.title : file.filename}
                                    </a>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    }

    _renderNews() {
        return this.state.news.map((news) => {
            return (
                <div key={news._id} className="mb-3">
                    <div className="card bg-light text-dark" style={{}}>
                        <div className="card-body">
                            <h5 className="card-title">{news.title}</h5>
                            <p
                                className="card-text card-news-body"
                                dangerouslySetInnerHTML={{ __html: news.content }}
                            ></p>
                            {news.files.length > 0 && this._renderFiles(news)}
                        </div>
                        <div className="card-footer small p-2">
                            <IconDisplay icon="user" text={news.user.firstname + " " + news.user.lastname} />
                            <IconDisplay icon="calendar" text={moment(news.createdDate).format("DD.MM.YYYY")} />
                        </div>
                    </div>
                </div>
            );
        });
    }

    componentDidMount() {
        this._loadNews();
    }

    async _loadNews() {
        try {
            let news = await Request("api/portal/news");
            this.setState({ news: news.data });
        } catch (err) {
            console.log("Catched new loading error");
            this.setState({ news: [] });
        }
    }

    render() {
        return (
            <div>
                {this.state.news.length > 0 && this._renderNews()}
                {this.state.news.length === 0 && <p>Derzeit sind keine Einträge vorhanden.</p>}
            </div>
        );
    }
}

function IconDisplay(props) {
    return (
        <span className={props.text ? "mr-2" : ""}>
            <FontAwesomeIcon icon={["fal", props.icon]} fixedWidth={props.text ? true : false} />{" "}
            {props.text ? " " + props.text : ""}
        </span>
    );
}
