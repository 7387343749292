import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({ mod }) => {
  return (<div>
    <div className={'mb-2'}>
      <p className={"display-6"}>
        <FontAwesomeIcon icon={['fal', 'spinner-third']} spin fixedWidth /> {mod} wird vorbereitet
      </p>
      
    </div>
  </div>)
}