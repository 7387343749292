import React, { useState, useContext, useEffect } from "react";
import useReactRouter from "use-react-router";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import _ from "lodash";
import Tippy from "@tippyjs/react";

import Request from "Library/Request";
import { AuthContext } from "Library/ContextAuthentification";
import { ThemeContext } from "Library/Theme";

import ProjectRouter from "../Router";

import "./layout.scss";
import logo from "../assets/images/caritas-logo.png";
import logoCollapsed from "../assets/images/favicon-32x32.png";

export default function Layout(props) {
    const [navigation, setNavigation] = useState({});
    const [collapsed, setCollapsed] = useState(false);

    const { location, history, match } = useReactRouter();
    const authProps = useContext(AuthContext);
    const theme = useContext(ThemeContext);

    const size = useWindowSize();

    useEffect(() => {
        setCollapsed(size.width < 1025);
    }, [size]);

    useEffect(() => {
        try {
            _loadNav();
        } catch (err) {
            console.log("Caught error:", err);
        }
    }, [authProps.userData]);

    const _loadNav = async () => {
        let _navigation = {};
        try {
            _navigation = await Request('api/navigation');
        } catch (err) {
            console.log("Catched navigation loading error");
            _navigation.data = [];
        }

        // Sort subnavigations by order
        _navigation = _.map(_navigation.data, (d) => ({
            ...d,
            subnavigations: _.sortBy(d.subnavigations, ["order"]),
        }));

        // Sort navigation by order
        _navigation = _.sortBy(_navigation, ["order"]);

        setNavigation(_navigation);
    };

    const renderNavigation = _.map(navigation, (element) => {
        if (element.type === "Dropdown") {
            let hasActiveElement = false;
            _.each(element.subnavigations, (subelement) => {
                if (location.pathname === "/" + subelement.href) hasActiveElement = true;
                if (location.pathname.includes(subelement.href)) hasActiveElement = true;

                if (location.pathname.includes("/catalog/view/") && subelement.href.includes("catalog/"))
                    hasActiveElement = true;
                if (location.pathname.includes("/project/view/") && subelement.href.includes("project/"))
                    hasActiveElement = true;
                if (
                    location.pathname.includes("/applicant-management") &&
                    subelement.href.includes("applicant-management/")
                )
                    hasActiveElement = true;

                /*if (location.pathname.includes('/qualitymanagement/administration') && subelement.href.includes('qualitymanagement/')) hasActiveElement = true;*/
            });

            return (
                <React.Fragment key={element._id}>
                    {!collapsed && (
                        <li
                            className={"collapsed " + (hasActiveElement ? "active" : "")}
                            data-toggle="collapse"
                            data-target={"#sub_" + element._id}
                            aria-expanded="false"
                        >
                            <span className="px-2">
                                <FontAwesomeIcon icon={["fal", element.icon]} fixedWidth />
                            </span>{" "}
                            {element.title}{" "}
                            <span style={{ float: "right" }} className="mr-2">
                                <FontAwesomeIcon icon={["fal", "chevron-down"]} fixedWidth />
                            </span>
                        </li>
                    )}

                    {collapsed && (
                        <Tippy content={element.title} placement={"right"}>
                            <li
                                className={"collapsed " + (hasActiveElement ? "active" : "")}
                                data-toggle="collapse"
                                data-target={"#sub_" + element._id}
                                aria-expanded="false"
                            >
                                <span className="px-2">
                                    <FontAwesomeIcon icon={["fal", element.icon]} fixedWidth />
                                </span>
                            </li>
                        </Tippy>
                    )}

                    <ul className={"sub-menu collapse " + (hasActiveElement ? "show" : "")} id={"sub_" + element._id}>
                        {_.map(element.subnavigations, (subelement) => {
                            let to = "/" + subelement.href;
                            let activeClass = "";
                            activeClass = location.pathname.indexOf(to) > -1 ? "active" : "";

                            if (!collapsed) {
                                return (
                                    <li key={element._id + "_" + subelement.href} className={activeClass}>
                                        <NavLink
                                            to={to}
                                            style={{ display: "inline-block", color: subelement.color }}
                                            activeClassName="noclass"
                                            className="w-100"
                                        >
                                            <span style={{ display: "inline-block" }} className="mr-2 small text-muted">
                                                <FontAwesomeIcon icon={["fal", "chevron-right"]} fixedWidth />
                                            </span>
                                            <FontAwesomeIcon icon={["fal", subelement.icon]} fixedWidth />{" "}
                                            {subelement.title}
                                        </NavLink>
                                    </li>
                                );
                            } else {
                                return (
                                    <Tippy
                                        content={subelement.title}
                                        placement={"right"}
                                        key={element._id + "_" + subelement.href}
                                    >
                                        <li className={classnames(activeClass)}>
                                            <NavLink
                                                to={to}
                                                style={{ display: "inline-block", color: subelement.color }}
                                                activeClassName="noclass"
                                                className="w-100"
                                            >
                                                <FontAwesomeIcon icon={["fal", subelement.icon]} fixedWidth />
                                            </NavLink>
                                        </li>
                                    </Tippy>
                                );
                            }
                        })}
                    </ul>
                </React.Fragment>
            );
        } else {
            if (!collapsed) {
                return (
                    <li key={element._id}>
                        <NavLink
                            to={"/" + element.href}
                            style={{ display: "block", color: element.color }}
                            placement={"right"}
                        >
                            <span className="px-2">
                                <FontAwesomeIcon icon={["fal", element.icon]} fixedWidth />
                            </span>{" "}
                            {element.title}
                        </NavLink>
                    </li>
                );
            } else {
                return (
                    <Tippy content={element.title} placement={"right"} key={element._id}>
                        <li>
                            <NavLink to={"/" + element.href} style={{ display: "block", color: element.color }}>
                                <span className="px-2">
                                    <FontAwesomeIcon icon={["fal", element.icon]} fixedWidth />
                                </span>
                            </NavLink>
                        </li>
                    </Tippy>
                );
            }
        }
    });

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
            <div
                className={classnames("d-print-none p-3 nav-container", collapsed ? "nav-collapsed" : "")}
                style={{ backgroundColor: "#23282e" }}
            >
                <div className={classnames("nav-side-menu")}>
                    <div className="brand">
                        <a href="#portal">{<img src={collapsed ? logoCollapsed : logo} alt="Logo" />}</a>
                    </div>

                    <div className="menu-list">
                        <ul className="menu-content collapse out">{renderNavigation}</ul>
                        <ul className="mt-4 menu-content collapse out">
                            <li>
                                {!authProps.isProcessing && authProps.userData && (
                                    <NavLink to="/login" style={{ display: "block" }}>
                                        <span className="px-2">
                                            <FontAwesomeIcon icon={["fad", "user"]} fixedWidth />
                                        </span>
                                        {!collapsed && authProps.userData.firstname}{" "}
                                        {!collapsed && authProps.userData.lastname}
                                    </NavLink>
                                )}

                                {authProps.isProcessing && (
                                    <span style={{ display: "block" }}>
                                        <span className="px-2">
                                            <FontAwesomeIcon icon={["fas", "spinner-third"]} spin fixedWidth />
                                        </span>{" "}
                                        {!collapsed && "Login läuft..."}
                                    </span>
                                )}

                                {!authProps.isProcessing && !authProps.userData && (
                                    <NavLink to={"/login"} style={{ display: "block" }}>
                                        <span className="px-2">
                                            <FontAwesomeIcon icon={["fad", "sign-in"]} fixedWidth />
                                        </span>{" "}
                                        {!collapsed && "Anmelden"}
                                    </NavLink>
                                )}
                            </li>
                        </ul>
                    </div>

                    <div className={classnames("pointer mt-5", collapsed ? "text-center" : "")}>
                        <div className={"mb-4 ml-1"} style={{ maxWidth: "200px" }}>
                            {theme.switch(collapsed)}
                        </div>

                        <div
                            className="text-secondary"
                            onClick={() => {
                                history.push("/about");
                            }}
                        >
                            <FontAwesomeIcon icon={["far", "info"]} fixedWidth />{" "}
                            {!collapsed && "Über das InfoNet Portal"}
                        </div>

                        {authProps.can("Administration", "System-Statistik") && (
                            <div
                                className="mt-3 text-secondary"
                                onClick={(e) => window.open("/swagger-stats/ui", "_blank")}
                            >
                                <FontAwesomeIcon icon={["far", "chart-bar"]} fixedWidth />{" "}
                                {!collapsed && "System-Statistik"}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                id="portalcontent"
                className={classnames([theme.text, theme.portalcontent])}
                style={{ flexGrow: 1, height: "100%" }}
            >
                <div id="divPortalFrame">
                    <main role="main">
                        <div id="content" className={"p-3"}>
                            <ProjectRouter updateNav={_loadNav} />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

function useWindowSize() {
    const isClient = typeof window === "object";

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}
