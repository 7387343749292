import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function About(props) {

  const caritasOldenburgLink = <><a href='https://www.caritas-ol.de/' target='_blank' rel="noopener noreferrer">Caritas Oldenburg</a></>
  const devLink = <><FontAwesomeIcon icon={['fad', 'browser']} fixedWidth /> <a href='https://www.danieldebuhr.de'>www.danieldebuhr.de</a></>
  const devMail = <><FontAwesomeIcon icon={['fad', 'envelope']} fixedWidth /> <a href='mailto:info@danieldebuhr.de?subject=InfoNet Portal'>info@danieldebuhr.de</a></>
  const fire = <FontAwesomeIcon icon={['fad', 'fire']} color='red' fixedWidth />
  const terminal = <FontAwesomeIcon icon={['fad', 'brackets-curly']} color='red' fixedWidth />
  const heart = <FontAwesomeIcon icon={['fad', 'heart']} color='red' fixedWidth />

  return (<div>

    <p className="display-6 pb-2">
      Über das InfoNet Portal
    </p>

    <h3>{heart} Partner</h3>

    <p>Entstanden ist das InfoNet Portal in enger Zusammenarbeit mit der {caritasOldenburgLink}.<br />
      Insbesondere Karin Weber möchte ich an dieser Stelle erwähnen. Danke Karin!</p>

    <h3 className='mt-4'>{terminal} Entwicklung</h3>
    <p>Daniel de Buhr, IT-Beratung und Softwareentwicklung</p>
    <p>Verantwortlich für diese Webpräsenz.</p>
    <p>{devLink}<br />{devMail}</p>

    <h3 className='mt-4'>{fire} Open Source</h3>
    <p>Vielen Dank an die großartige JavaScript Open-Source Community, ohne die dieses Projekt<br />
      nicht möglich gewesen wäre.</p>
    <p>Verwendete Open Source Software (Auswahl)</p>
    <ul>
      <li><a href='https://github.com/facebook/react/' target='_blank' rel="noopener noreferrer">React</a> (MIT) by Facebook</li>
      <li><a href='https://github.com/twbs/bootstrap/' target='_blank' rel="noopener noreferrer">Bootstrap</a> (MIT) by Twitter</li>
      <li><a href='https://github.com/transloadit/uppy' target='_blank' rel="noopener noreferrer">Uppy</a> (MIT) by Transloadit</li>
      <li><a href='https://github.com/atomiks/tippyjs-react' target='_blank' rel="noopener noreferrer">tippyjs for React</a> (MIT)</li>
      <li><a href='https://github.com/axios/axios' target='_blank' rel="noopener noreferrer">axios</a> (MIT)</li>
      <li><a href='https://github.com/lodash/lodash' target='_blank' rel="noopener noreferrer">lodash</a> (MIT)</li>
      <li><a href='https://github.com/moment/moment' target='_blank' rel="noopener noreferrer">Moment.js</a> (MIT)</li>
      <li><a href='https://github.com/react-bootstrap-table/react-bootstrap-table2' target='_blank' rel="noopener noreferrer">react-bootstrap-table2</a> (MIT)</li>
      <li><a href='https://github.com/diegomura/react-pdf' target='_blank' rel="noopener noreferrer">react-pdf</a> (MIT)</li>
      <li><a href='https://github.com/ReactTraining/react-router' target='_blank' rel="noopener noreferrer">react-router</a> (MIT)</li>
      <li><a href='https://github.com/fkhadra/react-toastify' target='_blank' rel="noopener noreferrer">react-toastify</a> (MIT)</li>
    </ul>

  </div>)

}