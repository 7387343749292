import axios from 'axios'

axios.defaults.withCredentials = true;

export default async function request(url, opt) {
    return new Promise((resolve, reject) => {
        
        if (!opt) opt = {};
        if (!opt.axios) opt.axios = {}
        let method = opt && opt.method ? opt.method : 'GET';

        let options = {
            ...opt.axios,
            method: method,
            url: url,
            headers: { ...opt.axios.headers },
            widthCredentials: true
        }

        if (opt && opt.data) {
            options.data = opt.data
        }

        //if(opt.debug) 
        //console.log(options)

        axios(options).then(function (response) {
            resolve(response.data);
        }).catch((err) => {
            //console.log(err.code)
            
            if(err.code === 403) {

            }
            reject(err)
        });


    });
}


