import React from 'react';
import _ from 'lodash';
import Request from 'Library/Request'
import deepEqual from 'deep-equal';
const AuthContext = React.createContext();

class AuthProvider extends React.Component {

    constructor(props) {

        super(props);

        this.whoami = this.whoami.bind(this);
        this.setLogout = this.setLogout.bind(this);
        this.setPasswordChangeNeeded = this.setPasswordChangeNeeded.bind(this);
        this.setAllowedRoutes = this.setAllowedRoutes.bind(this);
        this.updateAuthProps = this.updateAuthProps.bind(this)
        this.can = this.can.bind(this)
        this.getUser = this.getUser.bind(this)
        this.userDataSuccess = this.userDataSuccess.bind(this)

        this.state = {
            isProcessing: false,
            userData: undefined,
            autoLogin: this.autoLogin,
            error: '',
            passwordChangeNeeded: false,
            allowedRoutes: [],
            errorMode: '',
            can: this.can,
            updateAuthProps: this.updateAuthProps,
            getUser: this.getUser,
            userDataSuccess: this.userDataSuccess,
            setAllowedRoutes: this.setAllowedRoutes,
            setPasswordChangeNeeded: this.setPasswordChangeNeeded,
            setLogout: this.setLogout
        };

    }

    componentDidMount() {
        this.initWithCookie();
    }

    async initWithCookie() {

        const result = await this.whoami();
        if (result) {
            this.userDataSuccess(result)
        }

    }

    getUser() {
        const _user = this.state.userData;
        _user._id = _user._id.toString()
        delete _user.acl;
        delete _user.__v;
        delete _user.deleted;
        //delete _user.mail;
        delete _user.userId;
        delete _user.passwordChangeNeeded;
        delete _user.username;
        return _user
    }

    async whoami() {
        try {
            const whoami = await Request('api/whoami');
            return whoami
        } catch (err) {
            this.setLogout();
            return false;
        }
    }

    can(mod, target) {
        return this.state.userData && this.state.userData.acl ? _.findIndex(this.state.userData.acl, a => a.module === mod && (a.target === target || a._id === target)) > -1 : false
    }

    setAllowedRoutes(routes) {
        this.setState({ ...this.state, allowedRoutes: routes })
    }

    setPasswordChangeNeeded() {
        this.setState({ ...this.state, isProcessing: false, passwordChangeNeeded: true, error: null });
    }

    setProcessing() {
        this.setState({ ...this.state, isProcessing: true, userData: null, error: null });
    }

    setLogout() {
        this.setState({ ...this.state, userData: null, passwordChangeNeeded: false, isProcessing: false, error: null });
    }

    userDataSuccess(userData) {
        this.setState({
            ...this.state,
            userData,
            isProcessing: false,
            error: null,
            passwordChangeNeeded: userData.passwordChangeNeeded,
            allowedRoutes: userData && userData.acl ? userData.acl.filter(a => a.href).map(a => a.href) : []
        });
    }

    async updateAuthProps() {
        let userData = await this.whoami();
        if (!deepEqual(userData, this.state.userData)) {
            this.setState({
                userData: userData
            })
        }
        return true
    }

    async logout() {
        await Request('auth/logout', {
            method: 'POST'
        })

    }

    render() {
        return (
            <AuthContext.Provider value={this.state}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }

}

function withAuth(WrappedComponent) {

    class WithAuth extends React.Component {

        render() {
            const { forwardedRef, ...rest } = this.props;
            return (
                <AuthConsumer>
                    {(authProps) => {
                        return (
                            <WrappedComponent authProps={authProps} ref={forwardedRef} {...rest} />
                        )
                    }}
                </AuthConsumer>
            )
        }
    }

    return React.forwardRef((props, ref) => {
        return <WithAuth {...props} forwardedRef={ref} />
    })

}

const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer, withAuth, AuthContext }