import React from 'react';
import { toast } from 'react-toastify';

export default function ToastSuccess (text) {
  toast.success(<React.Fragment>
    {text}
  </React.Fragment>, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
}