import React, {Component} from "react";
import {Redirect} from 'react-router'
import {AuthConsumer} from 'Library/ContextAuthentification';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


export default class Logout extends Component {

    componentDidMount() {
        if(this.props.authProps.userData) {
            this.props.authProps.logout();
        }
    }

    render() {
        return (
            <AuthConsumer>
                {(props) => {
                    if (props.userData) {
                        return (
                            <div>
                                <FontAwesomeIcon icon={"spinner-third"} spin fixedWidth/> Sie werden abgemeldet....
                            </div>
                        )
                    } else {
                        return (
                            <Redirect to="/"/>
                        )
                    }
                }}
            </AuthConsumer>

        )
    }
}