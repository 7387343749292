import React, { useContext, useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { AuthContext } from 'Library/ContextAuthentification';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from "reactstrap";
import ToastSuccess from "Components/ToastSuccess"
import useReactRouter from 'use-react-router';
import request from "Library/Request";

export default function LoginPage(props) {

    const [form, setValues] = useState({
        username: '',
        password: '',
        newPassword: '',
        newPassword2: ''
    });

    const [showPasswordUpdate, setShowPasswordUpdate] = useState(false)
    const [error, setError] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [passwordOk, setPasswordOk] = useState({ ok: false, message: "Bitte gebe ein neues Kennwort ein" });

    const authProps = useContext(AuthContext)

    const handleLogin = async (event) => {
        event.preventDefault();
        setError(false)
        setIsProcessing(true)
        try {
            const response = await request('auth/login', {
                data: {
                    username: form.username,
                    password: form.password
                },
                method: 'POST'
            })
            if (response.passwordChangeNeeded) {
                authProps.setPasswordChangeNeeded();
            } else {
                authProps.userDataSuccess(response)
            }
        } catch (err) {
            if(err.response?.status === 401) setError("Benutzername oder Kennwort falsch.")
            if(err.response?.status === 429) setError("Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.")
        }
        setIsProcessing(false)
    }

    const handlePasswordChange = async (event) => {
        event.preventDefault();
        setIsProcessing(true)
        try {
            const response = await request('auth/change', {
                data: {
                    username: authProps?.userData?.username,
                    newpassword: form.newPassword
                },
                method: 'POST'
            })
            if (response.error) {
                setError("Fehler beim Ändern des Kennwortes.")
            } else {
                setShowPasswordUpdate(false)
                authProps.updateAuthProps();
            }
        } catch (err) {
            setError("Fehler beim Ändern des Kennwortes.")
        }
        setIsProcessing(false)
    }

    const handleLogout = async (event) => {
        event.preventDefault();
        setIsProcessing(true)
        try {
            await request('auth/logout', {
                method: 'POST'
            })
            authProps.setLogout();
        } catch (err) {
            setError("Fehler beim Abmelden.")
        }
        setIsProcessing(false)
    }

    const updateField = e => {
        setValues({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        const result = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[*.!@#$%^&()\[\]:;<>,?\\/~_+\-=]).{8,32}$/.exec(form.newPassword)
        if (form.newPassword.length === 0) setPasswordOk({ ok: false, message: <span>Bitte gebe ein neues Kennwort ein. Die Mindestlänge beträgt <b>8 Zeichen</b> und es muss jeweils min. <b>ein Großbuchstabe</b>, <b>eine Zahl</b> und <b>ein Sonderzeichen</b> enthalten.</span> })
        else if (!result) setPasswordOk({ ok: false, message: <span>Die Mindestlänge beträgt <b>8 Zeichen</b> und es muss jeweils min. <b>ein Großbuchstabe</b>, <b>eine Zahl</b> und <b>ein Sonderzeichen</b> enthalten.</span> })
        else if (form.newPassword.length > 0 && form.newPassword2.length === 0) setPasswordOk({ ok: false, message: <span>Bitte wiederhole das gleiche Kennwort noch einmal.</span> })
        else if (form.newPassword !== form.newPassword2) setPasswordOk({ ok: false, message: <span>Das wiederholte Kennwort stimmt noch nicht mit dem Ersten überein.</span> })
        else setPasswordOk({ ok: true })
    }, [form])

    let newPasswordEnterState = form.newPassword.length > 0 && form.newPassword2.length > 0;
    let newPasswordState = newPasswordEnterState && form.newPassword === form.newPassword2;

    if (authProps.userData) {
        return (
            <div>

                <p className="display-6 pb-2">
                    <FontAwesomeIcon icon={["fad", 'user']} className={'text-success'} fixedWidth /> {authProps.userData.firstname} {authProps.userData.lastname}
                </p>

                <div>

                    <p>Sie sind als {authProps.userData.firstname} {authProps.userData.lastname} (<i>{authProps.userData.username}</i>) angemeldet.</p>

                    <button onClick={e => handleLogout(e)} className="mt-3 btn btn-warning">
                        <FontAwesomeIcon icon={['fal', 'sign-out-alt']} fixedWidth /> Abmelden
                    </button>

                    <button onClick={e => {
                        setValues({ ...form, password: '', newPassword: '', newPassword2: '' })
                        setShowPasswordUpdate(true)
                    }} className="mt-3 ml-3 btn btn-info">
                        <FontAwesomeIcon icon={['fal', 'lock-open']} fixedWidth /> Kennwort ändern
                    </button>

                    {authProps?.userData?.username === 'admin' && <button className="mt-3 btn btn-info ml-3" onClick={async e => { await authProps.updateAuthProps(); ToastSuccess('Erfolgreich neu geladen'); }}>
                        <FontAwesomeIcon icon={["fas", 'sync']} fixedWidth /> Neu laden
                    </button>}

                </div>


                {showPasswordUpdate && <div className={'mt-5'}>
                    {!passwordOk.ok &&
                        <div className="col-6"><Alert color={'info'}><FontAwesomeIcon fixedWidth icon={'exclamation'} /> {passwordOk.message}</Alert></div>
                    }
                    <div className="col-6"><FormGroup>
                        <Label for="newPassword">Neues Kennwort</Label>
                        <Input value={form.newPassword} onChange={updateField}
                            type="password"
                            name="newPassword" id="newPassword" />
                    </FormGroup>
                    </div>
                    <div className="col-6">
                        <FormGroup>
                            <Label for="newPassword2">Neues Kennwort <small>(wiederholen)</small></Label>
                            <Input value={form.newPassword2} onChange={updateField}
                                type="password"
                                name="newPassword2" id="newPassword2" />
                        </FormGroup>
                    </div>
                    <div className="col-6">

                        <Button color={'success'} onClick={handlePasswordChange} disabled={!passwordOk.ok}>{authProps.isProcessing ? <FontAwesomeIcon icon={"spinner-third"} spin /> : 'Kennwort ändern...'}</Button>
                    </div>
                </div>}

            </div>
        )
    } else {

        let usernameAndPasswordState = form.username.length > 0 && form.password.length > 0;

        let buttonState = (authProps.passwordChangeNeeded && newPasswordEnterState && newPasswordState && usernameAndPasswordState) || (!authProps.passwordChangeNeeded && usernameAndPasswordState)

        return (
            <div>

                <p className="display-6 pb-2">
                    Im InfoNet Portal anmelden
                </p>

                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <Form method="POST" onSubmit={handleLogin}>

                            <Alert color={'info'} className={authProps.passwordChangeNeeded ? '' : 'd-none'}><FontAwesomeIcon fixedWidth icon={'key'} /> Sie müssen Ihr Kennwort ändern.</Alert>

                            <fieldset disabled={isProcessing}>

                                {!authProps.passwordChangeNeeded && <div>

                                    <FormGroup>
                                        <Label for="username">Benutzername</Label>
                                        <Input value={form.username} onChange={updateField}
                                            type="username"
                                            name="username" id="username" placeholder="" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">Kennwort</Label>
                                        <Input value={form.password} onChange={updateField}
                                            type="password"
                                            name="password" id="password" placeholder="" />
                                    </FormGroup>

                                    <Button type="submit" disabled={!buttonState}>
                                        {isProcessing
                                            ? <span><FontAwesomeIcon icon={"spinner-third"} spin fixedWidth /> Anmelden</span>
                                            : <span><FontAwesomeIcon icon={"sign-in"} fixedWidth /> Anmelden</span>}
                                    </Button>

                                </div>}

                                {authProps.passwordChangeNeeded && <div>
                                    {!passwordOk.ok &&
                                        <div className="col-12"><Alert color={'info'}><FontAwesomeIcon fixedWidth icon={'exclamation'} /> {passwordOk.message}</Alert></div>
                                    }
                                    <div className="col-12"><FormGroup>
                                        <Label for="newPassword">Neues Kennwort</Label>
                                        <Input value={form.newPassword} onChange={updateField}
                                            type="password"
                                            name="newPassword" id="newPassword" />
                                    </FormGroup>
                                    </div>
                                    <div className="col-12">
                                        <FormGroup>
                                            <Label for="newPassword2">Neues Kennwort <small>(wiederholen)</small></Label>
                                            <Input value={form.newPassword2} onChange={updateField}
                                                type="password"
                                                name="newPassword2" id="newPassword2" />
                                        </FormGroup>
                                    </div>
                                    <div className="col-12">
                                        <Button color={'success'} onClick={handlePasswordChange} disabled={!passwordOk.ok}>{isProcessing ? <FontAwesomeIcon icon={"spinner-third"} spin /> : 'Kennwort ändern...'}</Button>
                                    </div>
                                </div>}

                            </fieldset>
                            {error && 
                                <Alert className={'mt-3'} color={'danger'}>
                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-2" fixedWidth /> 
                                    <b>Fehler:</b> {error}
                                </Alert>
                            }
                        </Form>
                    </div>

                </div>


            </div>
        )

    }


}