import { library } from '@fortawesome/fontawesome-svg-core'

import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'



// Add all icons to the library so you can use it in your page
library.add(fas, fal, far, fad)

/*
// FAL
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity'
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUserEdit } from '@fortawesome/pro-light-svg-icons/faUserEdit'
import { faNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper'
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook'
import { faList } from '@fortawesome/pro-light-svg-icons/faList'
import { faThList } from '@fortawesome/pro-light-svg-icons/faThList'
import { faArchive } from '@fortawesome/pro-light-svg-icons/faArchive'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons/faSignOutAlt'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faCubes } from '@fortawesome/pro-light-svg-icons/faCubes'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faFileImage } from '@fortawesome/pro-light-svg-icons/faFileImage'
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord'
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet'
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf'
import { faFileArchive } from '@fortawesome/pro-light-svg-icons/faFileArchive'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck'
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck'
import { faBooks } from '@fortawesome/pro-light-svg-icons/faBooks'
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar'
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'


import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { faFire } from '@fortawesome/pro-solid-svg-icons/faFire'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'

import { faSave as faFasSave } from '@fortawesome/pro-solid-svg-icons/faSave'
import { faPlus as faFasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faExclamationTriangle as faFasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faList as faFasList } from '@fortawesome/pro-solid-svg-icons/faList'
import { faThList as faFasThList } from '@fortawesome/pro-solid-svg-icons/faThList'
import { faArchive as faFasArchive } from '@fortawesome/pro-solid-svg-icons/faArchive'
import { faCubes as faFasCubes } from '@fortawesome/pro-solid-svg-icons/faCubes'
import { faArrowLeft as faFasArrowLeft} from '@fortawesome/pro-solid-svg-icons/faArrowLeft'


// FAR
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faCalendar as faFarCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar'
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons/faCalendarPlus'
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons/faCalendarTimes'

library.add(
    faUniversity, faBuilding, faUser, faNewspaper, faBook, faList, faThList, faArchive, faCog, faHome, faUsers, 
    faChevronDown, faChevronRight, faSignOutAlt, faPlus, faCubes, faExclamationTriangle, faUserCheck, faUserEdit, faEye, faPencil,
    faCalendar, faFileImage, faFileWord, faFileSpreadsheet, faFilePowerpoint, faFilePdf, faFileArchive, faFileAlt, faTimes, faCheck,
    faCalendarCheck, faSearch, faSave, faArrowUp, faArrowDown, faArrowLeft, faArrowRight, faClipboardCheck, faBooks, faStar, faFolder, faFolderOpen,
    faQuestion, faBars
);

library.add(
    faSpinnerThird, faFire, faLock, faExclamation, faKey
)

library.add(
    faFasSave, faFasExclamationTriangle, faFasList, faFasThList, faFasArchive, faFasCubes, faFasPlus, faFasArrowLeft,
)

library.add(
    faTrash, faFarCalendar, faCalendarPlus, faCalendarTimes, 
)*/