import React, { Suspense, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { AuthContext } from "Library/ContextAuthentification";
import ProtectedRoute from "Components/ProtectedRoute";
import Loader from "Components/LoaderBig";

//Default Stuff
//import LandingPage from './Pages/Portal/LandingPage'
import News from "./Pages/News/News";
import Login from "./Pages/Login/Login";
import Logout from "./Pages/Login/Logout";
import About from "./Pages/Portal/About";

// Action
const CatalogList = React.lazy(() => import("./Pages/Catalog/CatalogList"));
const ActionNew = React.lazy(() => import("./Pages/Catalog/ActionNew"));
const ActionEdit = React.lazy(() => import("./Pages/Catalog/ActionEdit"));

// Project
const ProjectNew = React.lazy(() => import("./Pages/Project/ProjectNew"));
const ProjectEdit = React.lazy(() => import("./Pages/Project/ProjectEdit"));
const ProjectList = React.lazy(() => import("./Pages/Project/ProjectList"));

const AllTargets = React.lazy(() => import("./Pages/Project/AllTargets"));

// KM
const Competence = React.lazy(() => import("./Pages/Competence/Competence"));

// DataManagement
const FacilityList = React.lazy(() => import("./Pages/DataManagement/Facility/Facility"));
const User = React.lazy(() => import("./Pages/DataManagement/Users/Users"));
const Groups = React.lazy(() => import("./Pages/DataManagement/Groups/Groups"));
const Authorization = React.lazy(() => import("./Pages/DataManagement/Authorization/Authorization"));
const Protocol = React.lazy(() => import("./Pages/DataManagement/Protocol/Protocol"));

// QMH
const QMHandbooks = React.lazy(() => import("./Pages/QualityManagement/Handbook/Handbook"));
const QMFavorites = React.lazy(() => import("./Pages/QualityManagement/Favorites"));
const QMAdministration = React.lazy(() => import("./Pages/QualityManagement/Administration/Administration"));

const TasksSystem = React.lazy(() => import("./Pages/Tasks-System/tasks.js"));

const AMApplicants = React.lazy(() => import("./Pages/ApplicantManagement/applicants.js"));
const AMApplicant = React.lazy(() => import("./Pages/ApplicantManagement/ApplicantDetails/tabsApplicant.js"));
const AMJobs = React.lazy(() => import("./Pages/ApplicantManagement/jobs.js"));
const AMJob = React.lazy(() => import("./Pages/ApplicantManagement/JobDetails/tabsJobs.js"));
const AMJobNew = React.lazy(() => import("./Pages/ApplicantManagement/JobManager/new.js"));
const AMJobEdit = React.lazy(() => import("./Pages/ApplicantManagement/JobManager/edit.js"));
const AMSettings = React.lazy(() => import("./Pages/ApplicantManagement/settings.js"));

const Test = React.lazy(() => import("./Pages/Portal/Test"));

export default function Router(props) {
    const authProps = useContext(AuthContext);

    return (
        <Switch>
            {/* Default Stuff */}
            <Route exact path="/" component={News} />
            <ProtectedRoute exact path="/news" component={News} />
            <ProtectedRoute exact path="/portal" component={News} />
            <Route
                path="/login"
                render={(routeProps) => <Login {...Object.assign({}, routeProps, { authProps: authProps })} />}
            />
            <Route
                path="/logout"
                render={(routeProps) => <Logout {...Object.assign({}, routeProps, { authProps: authProps })} />}
            />
            <Route path="/about" component={About} />

            {/* Action */}
            <ProtectedRoute
                exact
                path="/catalog/list"
                render={() => (
                    <Suspense fallback={<Loader mod={"Maßnahmenkatalog"} />}>
                        <CatalogList mode={"list"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/catalog/targets"
                render={() => (
                    <Suspense fallback={<Loader mod={"Maßnahmenkatalog"} />}>
                        <CatalogList mode={"targets"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/catalog/archive"
                render={() => (
                    <Suspense fallback={<Loader mod={"Maßnahmenkatalog"} />}>
                        <CatalogList mode={"archive"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/catalog/new"
                render={() => (
                    <Suspense fallback={<Loader mod={"Maßnahmenkatalog"} />}>
                        <ActionNew />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                path="/catalog/view/:id"
                render={() => (
                    <Suspense fallback={<Loader mod={"Maßnahmenkatalog"} />}>
                        <ActionEdit />
                    </Suspense>
                )}
            />

            {/* Project */}
            <ProtectedRoute
                exact
                path="/project/list"
                render={() => (
                    <Suspense fallback={<Loader mod={"Projektplan"} />}>
                        <ProjectList mode={"list"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/project/archive"
                render={() => (
                    <Suspense fallback={<Loader mod={"Projektplan"} />}>
                        <ProjectList mode={"archive"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/project/new"
                render={() => (
                    <Suspense fallback={<Loader mod={"Projektplan"} />}>
                        <ProjectNew />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                path="/project/view/:id/:tab"
                render={() => (
                    <Suspense fallback={<Loader mod={"Projektplan"} />}>
                        <ProjectEdit />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                path="/project/view/:id"
                render={() => (
                    <Suspense fallback={<Loader mod={"Projektplan"} />}>
                        <ProjectEdit />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                exact
                path="/targets"
                render={() => (
                    <Suspense fallback={<Loader mod={"Ziele"} />}>
                        <AllTargets />
                    </Suspense>
                )}
            />

            {/* QMH */}

            <ProtectedRoute
                exact
                path="/qualitymanagement/handbook/:id"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal"} />}>
                        <QMHandbooks />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                exact
                path="/qualitymanagement/handbook/:id/doc/:documentId"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal"} />}>
                        <QMHandbooks />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                exact
                path="/qualitymanagement/handbook/:id/box/:boxId"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal"} />}>
                        <QMHandbooks />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                exact
                path="/qualitymanagement/favorites"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal"} />}>
                        <QMFavorites />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/qualitymanagement/administration"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal Datenpflege"} />}>
                        <QMAdministration updateNav={props.updateNav} tab={"handbook"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/qualitymanagement/administration/:tab"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal Datenpflege"} />}>
                        <QMAdministration updateNav={props.updateNav} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/qualitymanagement/administration/:tab/:id"
                render={() => (
                    <Suspense fallback={<Loader mod={"DokuPortal Datenpflege"} />}>
                        <QMAdministration updateNav={props.updateNav} />
                    </Suspense>
                )}
            />

            {/* KM */}
            <ProtectedRoute
                path="/competence"
                render={() => (
                    <Suspense fallback={<Loader mod={"Kompetenzforum"} />}>
                        <Competence />
                    </Suspense>
                )}
            />

            {/* Tasks-System */}
            <ProtectedRoute
                path="/tasks"
                render={() => (
                    <Suspense fallback={<Loader mod={"Aufgaben"} />}>
                        <TasksSystem />
                    </Suspense>
                )}
            />

            {/* Applicant-Management */}
            {/*}
            

            <ProtectedRoute
                path="/applicant-management/applicant/:id"
                render={() => (
                    <Suspense fallback={<Loader mod={"Bewerbermanagement"} />}>
                        <AMApplicant />
                    </Suspense>
                )}
            />
            */}

            <ProtectedRoute
                path="/applicant-management/applicants"
                render={() => (
                    <Suspense fallback={<Loader mod={"Bewerbermanagement"} />}>
                        <AMApplicants />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                exact
                path="/applicant-management/applicant/:applicant"
                render={() => (
                    <Suspense fallback={<Loader mod={"Bewerbermanagement"} />}>
                        <AMApplicant tab={"overview"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/applicant-management/applicant/:applicant/:tab"
                render={() => (
                    <Suspense fallback={<Loader mod={"Bewerbermanagement"} />}>
                        <AMApplicant />
                    </Suspense>
                )}
            />            

            <ProtectedRoute
                exact
                path="/applicant-management/job/:jobId"
                render={() => (
                    <Suspense fallback={<Loader mod={"Bewerbermanagement"} />}>
                        <AMJob tab={"applicants"} />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/applicant-management/job/:jobId/:tab"
                render={() => (
                    <Suspense fallback={<Loader mod={"Bewerbermanagement"} />}>
                        <AMJob />
                    </Suspense>
                )}
            />
            
            <ProtectedRoute
                path="/applicant-management/jobs/list"
                render={() => (
                    <Suspense fallback={<Loader mod={"Stellenverwaltung"} />}>
                        <AMJobs mode={"list"} />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                path="/applicant-management/jobs/archive"
                render={() => (
                    <Suspense fallback={<Loader mod={"Stellenverwaltung"} />}>
                        <AMJobs mode={"list"} archive />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                path="/applicant-management/jobs/new"
                render={() => (
                    <Suspense fallback={<Loader mod={"Neue Stelle"} />}>
                        <AMJobNew mode={"new"} />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                path="/applicant-management/jobs/edit/:_id/:ref?"
                render={() => (
                    <Suspense fallback={<Loader mod={"Stellenverwaltung"} />}>
                        <AMJobEdit />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                path="/applicant-management/settings"
                render={() => (
                    <Suspense fallback={<Loader mod={"Einstellungen"} />}>
                        <AMSettings />
                    </Suspense>
                )}
            />

            {/* DataManagement */}
            <ProtectedRoute
                exact
                path="/settings/facilities"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Einrichtungen"} />}>
                        <FacilityList />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/settings/facilities/:facilityId"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Einrichtungen"} />}>
                        <FacilityList />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/settings/facilities/:facilityId/:departmentId"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Einrichtungen"} />}>
                        <FacilityList />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                path="/settings/users"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Benutzer"} />}>
                        <User />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/settings/groups"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Gruppen"} />}>
                        <Groups />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/settings/groups/:groupname"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Gruppen"} />}>
                        <Groups />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/settings/authorization"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Berechtigungen"} />}>
                        <Authorization />
                    </Suspense>
                )}
            />
            <ProtectedRoute
                exact
                path="/settings/protocol"
                render={() => (
                    <Suspense fallback={<Loader mod={"Administration: Protokoll"} />}>
                        <Protocol />
                    </Suspense>
                )}
            />

            <ProtectedRoute
                exact
                path="/test"
                render={() => (
                    <Suspense fallback={<Loader mod={"Test-Bereich"} />}>
                        <Test />
                    </Suspense>
                )}
            />
        </Switch>
    );
}
