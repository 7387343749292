import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Route } from 'react-router-dom';
import { AuthContext } from 'Library/ContextAuthentification';
import Login from "../Pages/Login/Login"

const ProtectedRoute = ({ component: Component, path, ...rest }) => {

    const authProps = useContext(AuthContext);
    const splittedPath = path.substr(1).split('/').slice(0, 2).join('/');

    if (authProps.userData && authProps.allowedRoutes && authProps.allowedRoutes.filter(a => a.includes(splittedPath)).length > 0) {
        return (
            <Route render={props => authProps ? <Component {...props} /> : <span><FontAwesomeIcon icon={"spinner-third"} spin fixedWidth /> Die Seite wird vorbereitet...</span>} {...path} {...rest} />
        )
    } else {
        return (<Login />)

    }
}

export default ProtectedRoute;

//<Redirect to="/"/>