import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ThemeContext = React.createContext();

class ThemeProvider extends React.Component {

    constructor(props) {

        super(props);

        let theme = localStorage.getItem('theme') || 'light';
        let vals = this.getVals(theme);

        this.toggle = this.toggle.bind(this)

        this.state = {
            theme: theme,
            switch: this.switch.bind(this),
            ...vals
        };



    }

    getVals(theme) {
        return {
            bg: theme === 'light' ? 'bg-light' : 'bg-dark',
            text: theme === 'light' ? 'text-dark' : 'text-light',
            table: theme === 'light' ? 'table-light' : 'table-dark',
            border: theme === 'light' ? '' : 'border-secondary',
            portalcontent: theme === 'light' ? 'portalcontent' : 'bg-dark',
            listGroup: theme === 'light' ? '' : 'list-dark',
            jsonView: theme === 'light' ? undefined : 'tomorrow',
            bgNormalized: theme === 'light' ? 'bg-white' : 'bg-dark',
        }
    }

    switch(collapsed) {
        return (
            <div>
                <div className="custom-control custom-switch pointer">
                    <input type="checkbox" className="pointer custom-control-input" id="customSwitch1" onChange={this.toggle} defaultChecked={(this.state.theme === 'light' ? false : true)} />
                    <label className="custom-control-label pointer text-secondary" htmlFor="customSwitch1">
                        {this.state.theme === 'light'
                            ? <span><FontAwesomeIcon icon={['fad', 'lightbulb']} fixedWidth /> {!collapsed && 'Hell-Modus'}</span>
                            : <span><FontAwesomeIcon icon={['fad', 'moon']} fixedWidth /> {!collapsed && 'Dunkel-Modus'}</span>}
                    </label>
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.theme !== prevState.theme) {
            this.setState({
                ...this.getVals(this.state.theme)
            })
        }
    }

    toggle(e) {
        localStorage.setItem('theme', (this.state.theme === 'light' ? 'dark' : 'light'))
        this.setState(prevState => ({ theme: (prevState.theme === 'light' ? 'dark' : 'light') }))
    }

    render() {
        return (
            <ThemeContext.Provider value={this.state}>
                {this.props.children}
            </ThemeContext.Provider>
        )
    }

}


function withTheme(WrappedComponent) {

    class WithTheme extends React.Component {

        render() {
            const { forwardedRef, ...rest } = this.props;
            return (
                <ThemeConsumer>
                    {(theme) => {
                        return (
                            <WrappedComponent theme={theme} ref={forwardedRef} {...rest} />
                        )
                    }}
                </ThemeConsumer>
            )
        }
    }

    return React.forwardRef((props, ref) => {
        return <WithTheme {...props} forwardedRef={ref} />
    })

}

const ThemeConsumer = ThemeContext.Consumer;
export { ThemeProvider, ThemeConsumer, ThemeContext, withTheme }