import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FileIconDisplay(props) {
    let fileType = props.fileType.toLowerCase();
    let icon = <FontAwesomeIcon icon={['fal', 'file-alt']} />;
    if(['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(fileType)) icon = <FontAwesomeIcon icon={['fal', 'file-image']} />;
    if(['doc', 'docx'].includes(fileType)) icon = <FontAwesomeIcon icon={['fal', 'file-word']} />;
    if(['xls', 'xlsx'].includes(fileType)) icon = <FontAwesomeIcon icon={['fal', 'file-spreadsheet']} />;
    if(['ppt', 'pptx'].includes(fileType)) icon = <FontAwesomeIcon icon={['fal', 'file-powerpoint']} />;
    if(['pdf'].includes(fileType)) icon = <FontAwesomeIcon icon={['fal', 'file-pdf']} />;
    if(['zip', 'rar'].includes(fileType)) icon = <FontAwesomeIcon icon={['fal', 'file-archive']} />;
    return icon;
}