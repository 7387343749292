import React, { Suspense, Component } from "react";
import NewsDisplay from "./NewsDisplay"
import { AuthConsumer } from 'Library/ContextAuthentification';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from 'Components/Loader';
const NewsAdmin = React.lazy(() => import("./NewsAdmin"));

export default class News extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'news',
            hideCreateButton: false
        };
        this.changeMode = this.changeMode.bind(this);
        this.newsCreated = this.newsCreated.bind(this);
    }

    changeMode(mode) {
        if ((this.state.mode === 'admin' && window.confirm("Achtung, ungespeicherte Änderungen werden verloren gehen!")) || this.state.mode !== 'admin') {
            this.setState(prevState => ({
                mode: mode ? mode : prevState.mode === 'news' ? 'admin' : 'news'
            }));
        }
    }

    newsCreated() {
        this.setState(prevState => ({
            mode: 'admin'
        }));
    }

    render() {
        let adminMode = this.state.mode === 'admin';
        let btnChangeMode = adminMode ? 'btn-info' : 'btn-outline-info';
        return (
            <div>
                <div className="row flex-nowrap d-print-none">
                    <div className="col-5 align-items-center  pb-3">
                        <span className="display-6"><FontAwesomeIcon icon={['fad', 'newspaper']} fixedWidth /> Aktuelles</span>
                    </div>
                    <AuthConsumer>
                        {(props) => {
                            return (
                                props.can('Aktuelles', 'News erstellen') && <div className="col-7 mb-3 text-right">
                                    <button onClick={(e) => { this.changeMode() }} className={btnChangeMode + ' btn'}>
                                        <FontAwesomeIcon icon={['fal', adminMode ? 'times' : 'cog']} fixedWidth /> {adminMode ? 'Verwalten beenden' : 'Verwalten'}
                                    </button>
                                </div>
                            )
                        }}
                    </AuthConsumer>
                </div>
                {this.state.mode === 'news' && <NewsDisplay />}
                {this.state.mode === 'admin' &&
                    <Suspense fallback={<Loader text={'News-Administration wird geladen...'} />}>
                        <NewsAdmin />
                    </Suspense>
                }
            </div>
        )
    }
}