import React from 'react';

import { HashRouter } from "react-router-dom";

import 'bootstrap';

import './main.scss';

import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css'

import 'Library/FontAwesomeIcons'
import { AuthProvider } from 'Library/ContextAuthentification';
import { ThemeProvider } from 'Library/Theme';
import Layout from './Layout/Layout';
import { ToastContainer } from "react-toastify";

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        localStorage.removeItem('catalog_last_search_archive');
        localStorage.removeItem('catalog_last_search_targets');
        localStorage.removeItem('catalog_last_search_list');
        localStorage.removeItem('catalog_last_page');
        localStorage.removeItem('project_last_search_list');
        localStorage.removeItem('project_last_page');
    }


    render() {
        return (
            <HashRouter>
                <AuthProvider>
                    <ThemeProvider>

                        <ToastContainer draggablePercent={60} />
                        <Layout />
                        
                    </ThemeProvider>
                </AuthProvider>
            </HashRouter>
        )
    }

}